.Home {
    display: flex;
    flex-direction: column;
    background-color: #1b1a1b;
    
    margin-top: 3vw;
    margin-left: 10vw;
    margin-right: 10vw;

    /* height: 100%; */

    gap: 1vw;
    padding: 3vw;

    border-radius: 1vw;
}
.Home h1 {
    color: #715AFF;
}
.Home h2 {
    color: #F7F7F9;
}

.blog {
    width: 72vw;
    padding-top: 1vw;
    padding-bottom: 1vw;
}
.blog h1 {
    text-align: left;
    padding-left: 1vw;
    color: #715AFF;
    /* font-size: 2vw; */
    font-style: italic;
}
.blog h2 {
    padding-left: 1vw;
    text-align: left;
    /* font-size: 1vw; */
    color: #F7F7F9;

    text-decoration: underline;
}
.blog h3 {
    padding-left: 1vw;
    text-align: left;
    color: #F7F7F9;
}
.about p {
    color: #F7F7F9;
}
.pdf p {
    color: #F7F7F9;
}
.pdf {
    width: 70vw;
    height: 80vw;
}
.josh {
    width: 20vw;
}
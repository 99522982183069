.Header {
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 7vw;

    background-color: #1b1a1b;

}
.Header img {
    margin: 1vw;
}
.Header a {
    text-decoration: none;
    color: #715AFF;

    margin: auto auto;
    font-size: 3vw;

}

.about {
    text-align: left;
}
.Entry {
    display: flex;
    flex-direction: column;
    text-align: left;

    background-color: #362C28;
    border-style: solid;
    border-radius: 1vw;
    border-color: #191925;

    padding: 2vw;

    color: #D4FCC3;
    
}

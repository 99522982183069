* {
  text-decoration: none;
  user-select: none;
  transition: 300ms ease;
  margin: 0 auto;

  font-family: azo-mono, monospace;
  font-style: normal;
  font-weight: 100;

}

.App {
  text-align: center;
  height: 100%;
  background-color: #10090f;
  padding-bottom: 10vw;
}

